import { Flexbox } from '@stage-ui/core'
import PlusBlock from '@src/components/PlusBlock'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

const S02Perks = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox direction={['row', 'column']} justifyContent="space-between" {...props}>
      <PlusBlock title="Рост прибыли" label="Подробнее" anchor="grow" />
      <PlusBlock title="Контроль предприятия" label="Подробнее" anchor="video" />
      <PlusBlock title="Масштабируемость" label="Подробнее" anchor="scaling" />
      <PlusBlock title="Улучшение качества" label="Подробнее" anchor="quality" />
    </Flexbox>
  )
}

export default S02Perks
